import $ from 'jquery'
import {FamaUtils} from "../../classes/FamaUtils";

export class Simulator {
    utils = FamaUtils.getInstance();
    next = 0;
    selectedByUser = false;
    interval = '';

    constructor() {
        this.init();
    }

    init() {
        if($('#items').length > 0)
            this.interval = setInterval(() => {this.infiniteChanges()}, 7000);

        this.eventListeners();
    }

    changeMenuSimulator(e) {
        let $elem = $(e.currentTarget);
        let simulator= $elem.data('id');
        let simulatorSelected;

        $elem.closest('.menu-list').find('.list-item').each((i, elem) => {
            $(elem).removeClass('isActive');
        });

        $elem.addClass('isActive');

        $('.simulator-entry').removeClass('isActive');

        simulatorSelected=$('.simulator-entry .simulator[data-id="'+simulator+'"]');
        simulatorSelected.closest('.simulator-entry').addClass('isActive');

        if (!this.selectedByUser) {
            this.stopInfiniteChanges();
        }
    }

    infiniteChanges() {
        let indexArray = JSON.parse($('#items').val());
        let activeId = $('.js-change-menuSimulator.isActive').data('id');
        let simulatorSelected;

        for (let i = 0; i < indexArray.length; i++) {
            if (indexArray[i] === activeId) {
                this.next = ((i+1) >= indexArray.length) ? 0 : i+1;
            }
        }

        let $item = $('.list-item[data-id="'+indexArray[this.next]+'"]');

        $item.closest('.menu-list').find('.list-item').each((key, item) => {
            $(item).removeClass('isActive');
        });
        $item.addClass('isActive');
        $('.simulator-entry').removeClass('isActive');
        simulatorSelected = $('.simulator-entry .simulator[data-id="'+indexArray[this.next]+'"]');
        simulatorSelected.closest('.simulator-entry').addClass('isActive');
    }

    stopInfiniteChanges() {
        this.selectedByUser = true;
        clearInterval(this.interval);
    }

    eventListeners() {
        $('.js-change-menuSimulator').on('click', (e) => {this.changeMenuSimulator(e)});
    }

}
