// import $ from 'jquery'
import {FamaUtils} from "../../classes/FamaUtils";

export class General {
    utils = window.fama.utils ?? FamaUtils.getInstance();

    constructor() {
        this.init();
    }

    init() {
        fama.utils.debug('init General.js');
        this.eventListeners();
    }

    eventListeners() {
        $('.js-dropDown').on('click', (e) => {this.dropDown(e)});
        $('.js-close').on('click', (e) => {this.close(e)});
    }

    dropDown(e) {
        $(e.currentTarget).toggleClass('isOpen');
    }

    close(e) {
        //console.log('close');
        let $item = $(e.currentTarget);
        $item.closest('.content-open-close').removeClass('isOpen');
        $('body, html').removeClass('stop-scroll');

        if($item.closest('.form-newsletter').length>0){
            $item.closest('.form-newsletter').find('.error').each((key, item) =>{
                $(item).removeClass('error');
            })

            $item.closest('.form-newsletter').find('.err').each((key, item) =>{
                $(item).hide();
            })

            $item.closest('.form-newsletter').find('input[name="mailfastsuscription"]').each((key, item) =>{
                $(item).val('');
            })

            $item.closest('.form-newsletter').find('input.acept').each((key, item) =>{
                $(item).prop('checked', false);
            })

            if(!this.utils.checkCookie('isNewsletterCheck')) {
                var days=parseInt($('.form-newsletter').attr('data-day'));
                this.utils.setCookie("isNewsletterCheck", '1', days);
            }
        }
    }
}
