//Load Libraries
// import $ from "jquery";

// window.$ = window.jQuery = require('jquery');
// require('jquery.easing');
// require('slick-carousel');

//OTHER VENDOR (viene de vendor de fama)
require('../vendor/swiper.min.js');
// require('../vendor/swiper.jquery.min.js');
// require('../vendor/jquery-ui.min.js');

//GSAP (viene de vendor de fama) para que??
// require('../vendor/gsap/gsap.min.js');
// require('../vendor/gsap/draggable.min.js');
// require('../vendor/gsap/scrollTrigger.min.js');
// require('../vendor/gsap/scrollMagic.min.js');
// require('./vendor/app');


//require('./utils');
//require('./d.swiperSlider');
//require('./newsletter');
//require('./d.blog_coment');
//require('./d.contact');
//require('./d.stores');
//require('./select2');
//equire('./warranty');

// COMPONENTS
import {App} from "../app/App";
import { ModalFinder } from '../app/modules/ModalFinder.js';

// CLASSES
import {GalleryBox} from "./classes/GalleryBox";
import {General} from "./classes/General";
import {StoresFamaliving} from "./classes/StoresFamaliving";
import {Contact} from "./classes/Contact";
import {Sliders} from "./classes/Sliders";
import {Store} from "./classes/DetailStore";
import {Blog} from './classes/Blog';
import {BlogComment} from "./classes/BlogComment";
import {BlogAuthor} from "./classes/BlogAuthor";
import {Stores} from "./classes/ListStores";
import {Newsletter} from "./classes/Newsletter";
import {MapMenu} from "./classes/MapMenu";
import {Select2} from "./classes/Select2";
import {Warranty} from "./classes/Warranty";
import {GeoInit} from "./classes/GeoInit";
import {Like} from "../classes/Like";
import {LoginShops} from "./classes/LoginShops";
import {Topbar} from "./classes/Topbar";
import {Simulator} from "./classes/Simulator";
import {SplashScreen} from "../classes/SplashScreen";
import {MapStoreManagerFamaliving} from "./classes/MapStoreManagerFamaliving";
import { FamaUtils } from "../classes/FamaUtils.js";
import { LibraryLoader } from "../classes/LibraryLoader.js";
import { FavouriteHandler } from "../classes/FavouriteHandler.js";
import { Animatable } from "../classes/Animatable.js";
import { ScrollBackgroundSphere } from "../classes/ScrollBackgroundSphere.js";
import { ProductDetail } from "../classes/ProductDetail.js";
import { ProductListManager } from "../classes/ProductListManager.js";
import { Ratings } from "../classes/Ratings.js";
import { GeneralRatingsForm } from "../fama/modules/GeneralRatingsForm.js";
import { ProductSideMenu } from "../classes/ProductSideMenu.js";
import { CategoryListManager } from "../classes/CategoryListManager.js";

let utils = fama.utils || FamaUtils.getInstance()
utils.toast.setSettings({style:{background:"#c70087"}})

/**
 * On Ready
 */

$((e) => {
    console.log('************** famaliving.js loaded **************');
    let loadLib = LibraryLoader.getInstance();

    let splashScreen = SplashScreen.getInstance();
    splashScreen.init();

    // GeoInit
    let geoInit = new GeoInit();

    // Slider detail
    let sliders = Sliders.getInstance();

    // GalleryBox
    let galleryBox = new GalleryBox();

    // General
    let general = new General();

    // Store
    let storesFamaliving = new StoresFamaliving();

    // Contact
    if(fama.utils.exists('#contact_famaliving2023')) {
        let contact = new Contact();
    }

    // Detail Store
    if(fama.utils.exists('.new-store')) {
        let store = new Store();
    }

    // Blog
    if(fama.utils.exists('#js-blog')) {
        let blog = new Blog();
    }

    // Blog comment
    if(fama.utils.exists('#comentario_blog_famaliving')) {
        let blogComments = new BlogComment();
    }

    // Blog author
    if(fama.utils.exists('#js-blog-author')) {
        let blogAuthor = new BlogAuthor();
    }

    if(fama.utils.exists('.js-animatable')) {
        let animatable = new Animatable()
    }

    // Scroll Sphere
    if(fama.utils.existsOr(['.ballon-list','.ballon-top','.ballon-bottom'])) {
        let scrollSphere = new ScrollBackgroundSphere();
    }

    // Product detail (Loaded in products)
    if(fama.utils.existsOr(['app-detail_2021', 'app-detail'])){
        let pd = new ProductDetail();
    }

    // List Manager
    if(fama.utils.existsOr(['.js-listGrid','.js-listList'])) {
        let lm = new ProductListManager();
    }

    if( fama.utils.exists('.catalogos-slider')) {
        let cm = new CategoryListManager();
    }

    // Newsletter
    let newsletter = new Newsletter();

    // Select2
    let select2 = new Select2();

    // Warranty
    let warranty = new Warranty();

    // Like
    if(fama.utils.existsOr(['.liked', '.like-button'])){
        let like = new Like()
    }

    // Side menu
    if(fama.utils.exists('.sidebar-categories') ) {
        let sideMenu = new ProductSideMenu();
    }

    // Favourite Handler (Products)
    if(fama.utils.exists('.js-favourite')) {
        let fav = new FavouriteHandler();
    }

    let ratings = new Ratings();
    if(fama.utils.exists('#generalRatingID')) {
        // form.init();
        let grf = new GeneralRatingsForm();
        ratings.init();
    }

    // LoginShops
    // let loginShops = new LoginShops();

    // ModalFinder
    let modalFinder = new ModalFinder();
    modalFinder.init();

    // List Stores
    let listStores = new Stores();

    // Map menu
    if(fama.utils.exists('#mapMenu')) {
        loadLib.loadGoogleAsync().then(() => {
            let mapMenu = new MapMenu();
            mapMenu.init();
            if(fama.utils.exists('.wrap-store-map')) {
                mapMenu.initStore();
            }
        });
    }
    
    
    // Map
    if (fama.utils.exists('.container-shops #map')) {
        loadLib.loadGoogleAsync().then(() => {
       // setTimeout(() => {
            let map = new MapStoreManagerFamaliving();
            map.init();
       // }, 1000); //TODO: Get rid of this timeout!!!
        });
    }

    // Topbar
    let topBar = new Topbar();

    // Simulator
    if (fama.utils.exists('#items')) {
        let simulator = new Simulator();
    }

    // Initialize app
    window.app = new App({
        breakpointDesktop: 769,
    });

    window.app.init();

    const disableIosTextFieldZoom = () => {
        const el = document.querySelector('meta[name=viewport]');

        if (el !== null) {
            let content = el.getAttribute('content');
            let re = /maximum\-scale=[0-9\.]+/g;

            if (re.test(content)) {
                content = content.replace(re, 'maximum-scale=1.0');
            } else {
                content = [content, 'maximum-scale=1.0'].join(', ')
            }

            el.setAttribute('content', content);
        }
    };

    const checkIsIOS = () =>
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (checkIsIOS()) {
        disableIosTextFieldZoom();
    }

});
