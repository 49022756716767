//use stores list (famaliving) List all stores Famaliving 2023

import $ from 'jquery'
import {isEmpty} from 'lodash'

'use strict'
import {FamaUtils} from "../../classes/FamaUtils";

export class Stores {
    utils = FamaUtils.getInstance();

    /** Selectors **/
    $entryContainer = $('#js-listShop');

    constructor() {
        this.init();
    }

    init() {
        this.eventListeners();

        if (!this.utils.isLoad("select2", "$")) {
            return;
        }

        this.initSelect2();
        this.selectCountryDefault();

        $('.region-form-input').val('');
    };

    eventListeners() {
        $('.js-countryItem').on('click', (e) => {this.seeCountryStores(e)});
        $('#list-shops').on('select2:select', (e) => {this.changeProvince(e)});
        $('.js-changeCountryXs').on('click', () => {this.seeListCountryXs()});
        $('.js-countryXs').on('click', () => {this.closeCountryXs()});
        $('.js-inputProvince').on('input', () => {this.filterRegion()});
    }

    selectCountryDefault(){
        let cvalue=parseInt(this.utils.getCookie('geoCity'));
        if(this.utils.checkCookie('geoCity')) {
            $('.js-nameCountryXs').attr('data-countryid', cvalue);

            var options = {
                id: cvalue,
                lang: $('html').attr('lang'),
            }

            $.ajax({
                url: "/api/get-store-by-cookie-id",
                data: options,
                type: "post",
                async: options.async,
                dataType: "json",

                success: (r) => {
                    if (r.status == 'ok') {
                        $('.js-nameCountryXs').html(r.store.country_name);
                        $('.js-nameCountryXs').closest('.title.small-line').addClass('isVisible');
                        $('.js-countryItem[data-countryid='+ r.store.country_id +']').trigger('click');
                    }
                }
            });

        } else {
            let lang=this.utils.getCookie('geo');

            if (lang==='es'){
                $('.js-countryItem[data-countryid='+ 68 +']').trigger('click');
                $('.js-nameCountryXs').closest('.title.small-line').addClass('isVisible');
            } else {
                //add country for selected lang
                $('.js-countryItem[data-countryid='+ 68 +']').trigger('click');
                $('.js-nameCountryXs').closest('.title.small-line').addClass('isVisible');
            }

        }

    };

    seeCountryStores(e){
        e.preventDefault();
        let $elem = $(e.currentTarget);
        let countryId, countryName;
        $('.js-countryItem').removeClass('is-active');
        $('body, html').removeClass('stop-scroll');
        countryId=$elem.data('countryid');
        countryName=$elem.data('countryname');

        $('.js-countryItem[data-countryid='+ countryId +']').each((key, item) => {
            $(item).addClass('is-active');
        })

        $('.js-nameCountryXs').data('countryid', parseInt(countryId)); //put id country for mobile on change
        $('.js-nameCountryXs').html(countryName); //put name country for mobile on change

        $('.wrap-stores-country-xs').addClass('d-none');
        $('.content-list-stores .link-store').each((key, item) => {
            if(parseInt($(item).data('countryid'))!==countryId){
                $(item).addClass('d-none');

            }else{
                $(item).removeClass('d-none');
                if($(item).hasClass('isHiddenSearch') && !$('.page-stores .wrap-form').hasClass('d-none')){
                    $('.page-stores .wrap-form').addClass('d-none');
                }else if(!$(item).hasClass('isHiddenSearch')){
                    $('.page-stores .wrap-form').removeClass('d-none');
                }
            }


        });

        if(countryId===68){
            $('.form-change-region').fadeIn();
        }else{
            $('.form-change-region').fadeOut();
        }

        $('#list-shops').val('0').trigger('change.select2');
    };

    changeProvince(e){
        let $elem = $(e.currentTarget);
        var elemId= parseInt($elem.data('id'));

        $('.content-list-stores .link-store').each((key, item) => {
            if(parseInt($(item).data('regionid'))!=elemId){
                $(item).addClass('d-none');
            }else{
                $(item).removeClass('d-none');
            }
        });

        if(elemId===0){
            $('.content-list-stores .link-store[data-countryid='+ 68 +']').removeClass('d-none');
        }

    };

    filterRegion(){
        var elemValue = this.utils.removeAccents($('.region-form-input').val().toLowerCase());
        var countryId = $('.country-item.is-active').data('countryid');

        if(isEmpty(elemValue)){
            $('.content-list-stores .link-store').each((key, item) =>{
                var storeCountryId = $(item).data('countryid');
                if(storeCountryId==countryId) {
                    $(item).removeClass('d-none');
                }
            });
        } else {
            $('.content-list-stores .link-store').each((key, item) => {
                var storeName = this.utils.removeAccents($(item).find('.store-name').data('name').toLowerCase());
                var storeCountryId = $(item).data('countryid');
                if(storeName.includes(elemValue) && storeCountryId==countryId){
                    $(item).removeClass('d-none');
                }else{
                    $(item).addClass('d-none');
                }
            });
        }
    }

    seeListCountryXs(){
        $('.wrap-stores-country-xs').removeClass('d-none');
        $('.wrap-stores-country-xs').css('height', window.innerHeight + "px");
        $('body,html').addClass('stop-scroll');

    };
    closeCountryXs(){
        $('.wrap-stores-country-xs').addClass('d-none');
        $('body, html').removeClass('stop-scroll');
    };

    initSelect2(){
        if( $("select[name='list-shops']").length > 0){
            $("select[name='list-shops']").select2();
        }
    };
}
