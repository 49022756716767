
import MarkerClusterer from "@googlemaps/markerclustererplus";
//import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { FamaUtils } from "../../classes/FamaUtils";
import { Sliders } from "./Sliders";

/**
 *  Loaded on Home Screen Module
 */
export class MapStoreManagerFamaliving {
    utils = fama.utils || FamaUtils.getInstance();
    _map = null;
    _at = null;
    _kt = null;
    _markers = [];
    _markerCluster = null;
    _storeNear = [];

    GET_HTML_AUTOCOMPLETE = 0;
    GET_JS_AUTOCOMPLETE = 1;
    DONT_CENTER_ON_INIT = 0;
    NUMBER_OF_RESULTS = 0;
    CURRENT_ZOOM = 0;

    config= {};

    mapStyles = [];

    sliders = null;

    constructor() {
        this.utils.debug('MapStoreManagerFamaliving loaded')
        this.sliders = Sliders.getInstance();

        this.config = {
            distance: 245,
            threshold: 1,
            clusterRadius: 245
        };

        this.mapStyles = [
            {
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                ]
            },
            {
                "featureType": "administrative.country",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#575757"
                    },
                    {
                        "lightness": -30
                    },
                    {
                        "weight": 1
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#333333"
                    }
                ]
            },
            {
                "featureType": "administrative.land_parcel",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                ]
            },
            {
                "featureType": "administrative.province",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#d9d9d9"
                    },
                    {
                        "lightness": -35
                    },
                    {
                        "weight": 1
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#f5f5f5"
                    },
                    {
                        "saturation": -5
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "saturation": -5
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "color": "#333333"
                    },
                    {
                        "saturation": -5
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "saturation": 30
                    },
                    {
                        "lightness": 40
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "road",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#ffffff"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "saturation": -5
                    },
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "saturation": -35
                    },
                    {
                        "lightness": 100
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#757575"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "saturation": 5
                    },
                    {
                        "lightness": 5
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [
                    {
                        "color": "#ffffff"
                    },
                    {
                        "saturation": -5
                    },
                    {
                        "lightness": -100
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#616161"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            },
            {
                "featureType": "transit.line",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                ]
            },
            {
                "featureType": "transit.station",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#e9e9e9"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                ]
            }
        ];
    }

    init () {
        var markers = [];
        var selectedMarker;
        let map;
        let isInit=false; //is changed for init map without marker info defoult

        let markerInitLatLng=new google.maps.LatLng(38.0028590, -1.1281320);
        //console.log(this.mapStyles);
        var markerInitOptions = {
            zoom: 5,
            center: markerInitLatLng,
            disableDefaultUI: true,
            zoomControl: true,
            styles: this.mapStyles,
            //mapId: "map",

        }

        map = new google.maps.Map(document.getElementById("map"), markerInitOptions);
        this._map = map;

        window.storesToMap.forEach((store) => {
            const iconBase =
                "https://developers.google.com/maps/documentation/javascript/examples/full/images/";
            const icons = {
                famaliving: {
                    icon: '/images/redMarker.svg',
                },
                fama: {
                    icon: '/images/blueMarker.svg',
                },
                clicked: {
                    icon: '/images/blueMarkerChecked.svg',
                },
                clicked_famaliving: {
                    icon: '/images/redMarkerChecked.svg',
                }

            }

            let data = {
                address: store.address,
                city: store.city,
                country_name: store.country_name,
                region_name: store.region_name,
                country_id: store.country_id,
                created_at: store.created_at,
                email: store.email,
                facebook: store.facebook,
                fax: store.fax,
                fotos: store.fotos,
                highlight_zindex_map: store.highlight_zindex_map,
                id: store.id,
                imagenes: store.imagenes,
                instagram: store.instagram,
                iw4id: store.iw4id,
                language: store.language,
                mondayFriday: store.mondayFriday,
                name: store.name,
                phone: store.phone,
                po_box: store.po_box,
                point_latitude: store.point_latitude,
                point_longitude: store.point_longitude,
                postal_code: store.postal_code,
                rate_type: store.rate_type,
                region: store.region,
                region_id: store.region_id,
                saturday: store.saturday,
                send_newsletter: store.send_newsletter,
                show_in_fama: store.show_in_fama,
                show_in_famaliving: store.show_in_famaliving,
                updated_at: store.updated_at,
                web: store.web,
            };

            if(store.name.toUpperCase().indexOf('FAMALIVING') > -1){
                //2687
                //var marker = new google.maps.marker.AdvancedMarkerElement({
                var marker = new google.maps.Marker({
                    position: {lat: store.point_latitude, lng: store.point_longitude},
                    map: map,
                    //map,
                    scale: 5,
                    icon: icons.famaliving.icon,
                    data: data,
                    isFamaliving: true,
                });
                markers.push(marker);
            }else {
                //console.log('NO 124');
                //var marker = new google.maps.marker.AdvancedMarkerElement({
                var marker = new google.maps.Marker({
                    position: {lat: store.point_latitude, lng: store.point_longitude},
                    map: map,
                    //map,
                    scale: 5,
                    icon: icons.fama.icon,
                    data: data,
                    isFamaliving: false,
                });
                markers.push(marker);
            }
            this._markers=markers;

            google.maps.event.addListener(marker, 'click', () => {

                if (selectedMarker) {
                    selectedMarker.setIcon(selectedMarker.previousIcon);
                }

                selectedMarker = marker;
                selectedMarker.previousIcon = selectedMarker.getIcon();

                if(marker.isFamaliving){
                    marker.setIcon(icons.clicked_famaliving.icon);
                }else{
                    marker.setIcon(icons.clicked.icon);
                }

                if(isInit){

                    var po_box=marker.data.po_box ? marker.data.po_box : "";

                    $('.container-shops .map-container-info').html(`
                    <div>
                        <span>${marker.data.address}</span>
                        <span>${po_box}.</span>
                    </div>
                    <div>
                    <span>${marker.data.postal_code}</span>
                    <span>${marker.data.city}.</span>
                    <span>${marker.data.region_name}</span>
                    <span>(${marker.data.country_name})</span></div>`);
                    marker.setIcon(icons.famaliving.icon);
                    isInit=false;
                }else{
                    let $mapContainer=$('.container-shops .map-container-info');
                    let dir=$mapContainer.attr('data-dir');
                    let contact=$mapContainer.attr('data-contact');
                    let howToGo= $mapContainer.attr('data-howToGo');
                    let seeMap= $mapContainer.attr('data-seeMap');
                    let storeNear= $mapContainer.attr('data-storeNear');
                    let followFb=$mapContainer.attr('data-fb');
                    let followInst=$mapContainer.attr('data-inst');
                    let stores=[];
                    let storeSelected=[];

                    let partUpContact;
                    let partUpDir;
                    let partDownRedes;
                    let partDownStores;
                    let partDownStore;
                    let sliderShop;
                    let slideritem;
                    let sliderList=``;
                    let btnContact;

                    storeSelected=this._storeNear;

                    storeSelected.forEach((store) => {
                        if(store.id!==marker.data.id){
                            stores.push(store);
                        }
                    });
                    partUpDir=`
                    <div class="direction d-flex flex-column">
                        <div class="aux">
                            <p class="title">${dir}</p>
                            <p class="nameStory">${marker.data.name}</p>
                            <p class="address">${marker.data.address}</p><br>
                            <p> <span class="postal_code">${marker.data.postal_code}</span>, <span class="region_name">${marker.data.city} (${marker.data.region_name}) </span> </p>
                        </div>
                        <div class="wrap-btnDefault pointer" id="theWay">
                            <a href="http://maps.google.com/?q=${marker.data.point_latitude},${marker.data.point_longitude}" class="btnDefault" target="_blank">${howToGo}</a>
                        </div>
                    </div>`

                    if(marker.data.email !== '' && marker.data.email != null){
                        btnContact= `<div class="wrap-btnDefault pointer" id="contact-map">
                            <a href="mailto:${marker.data.email}" class="btnDefault" target="_blank">${contact}</a>
                        </div>`;
                    }

                    if((marker.data.phone !== '' && marker.data.phone != null) && (marker.data.web !== '' && marker.data.web != null)){
                        let webStore=marker.data.web;

                        if(webStore.toLowerCase().indexOf('http') === -1 ){
                            webStore="https://" + webStore;
                        }

                        partUpContact= `
                        <div class="contact d-flex flex-column">
                            <div class="aux">
                                <p class="title">${contact}</p>
                                <p class="tlf"><a href="tel:${marker.data.phone}">Tel: ${marker.data.phone}</a></p>
                                <p class="web"><a href="${webStore}" target="_blank">${marker.data.web}</a></p>
                            </div>

                        </div>`
                    }else if((marker.data.phone !== '' && marker.data.phone !== null) && (marker.data.web == '' || marker.data.web == null)){

                        partUpContact= `
                        <div class="contact d-flex flex-column">
                            <div class="aux">
                                <p class="title">${contact}</p>
                                <p class="tlf"><a href="tel:${marker.data.phone}" target="_blank">Tel: ${marker.data.phone}</a></p>
                            </div>
                        </div>`
                    } else if((marker.data.phone == '' || marker.data.phone == null) && (marker.data.web !== '' && marker.data.web !== null)){
                        let webStore=marker.data.web;


                        if(webStore.toLowerCase().indexOf('http') === -1 ){
                            webStore="https://" + webStore;
                        }

                        partUpContact= `
                        <div class="contact d-flex flex-column">
                            <div class="aux">
                                <p class="title">${contact}</p>
                                <p class="web"><a href="${webStore}" target="_blank">${marker.data.web}</a></p>
                            </div>
                        </div>`
                    }else{
                        partUpContact= `
                        <div class="contact d-flex flex-column">
                            <div class="aux">

                            </div>
                        </div>`
                    }

                    if((marker.data.facebook !== '' && marker.data.facebook !== null) && (marker.data.instagram !== '' || marker.data.instagram !== null)){
                        partDownRedes=`
                        <div class="part-redes d-flex flex-column">
                            <div class="aux"></div>
                            <a href="${marker.data.facebook}" class="red facebook" target="_blank">${followFb}</a>
                            <a href="${marker.data.instagram}" class="red instagram" target="_blank">${followInst}</a>
                        </div>`
                    }else if((marker.data.facebook !== '' && marker.data.facebook !== null) && (marker.data.instagram == '' || marker.data.instagram == null)){
                        partDownRedes=`
                        <div class="part-redes d-flex flex-column">
                            <div class="aux"></div>
                            <a href="${marker.data.facebook}" class="red facebook" target="_blank">${followFb}</a>
                        </div>`
                    }else if((marker.data.facebook == '' || marker.data.facebook == null) && (marker.data.instagram !== '' && marker.data.instagram !== null)){
                        partDownRedes=`
                        <div class="part-redes d-flex flex-column">
                            <div class="aux"></div>
                            <a href="${marker.data.instagram}" class="red instagram" target="_blank">${followInst}</a>
                        </div>`
                    }

                    if(stores.length>0){

                        partDownStores=`
                        <div class="title">${storeNear}</div> `;
                    }

                    if(stores.length>0){
                        if(stores.length===1){

                            for(let i=0; i<stores.length; i++){
                                let web=stores[i].web;

                                if(web !== '' && web !== null ){
                                    if(web.toLowerCase().indexOf('http') === -1 ){
                                        web="https://" + web;
                                    }
                                }

                                if(i===0){
                                    if(stores[i].name.toUpperCase().indexOf('FAMALIVING') > -1 && web !== '' && web !== null){

                                        partDownStore=`<div class="store" style="display: inline-flex;">
                                        <div class="storeName"><a href="${web}" class="btnDefault linkStore" target="_blank">${stores[i].name}</a></div>
                                        <div class="linksStores"><a href="http://maps.google.com/?q=${stores[i].point_latitude},${stores[i].point_longitude}" class="btnDefault" target="_blank">${seeMap}</a></div>
                                        </div>`
                                    }else{

                                        partDownStore=`<div class="store" style="display: inline-flex;">
                                        <div class="storeName">${stores[i].name}</div>
                                        <div class="linksStores"><a href="http://maps.google.com/?q=${marker.data.point_latitude},${stores[i].point_longitude}" class="btnDefault" target="_blank">${seeMap}</a></div>
                                        </div>`
                                    }

                                }else{

                                    if(stores[i].name.toUpperCase().indexOf('FAMALIVING') > -1 && web !== '' && web !== null){

                                        partDownStore = partDownStore + `<div class="store" style="display: inline-flex;">
                                        <div class="storeName"><a href="${web}" class="btnDefault linkStore" target="_blank">${stores[i].name}</a></div>
                                        <div class="linksStores"><a href="http://maps.google.com/?q=${stores[i].point_latitude},${stores[i].point_longitude}" class="btnDefault" target="_blank">${seeMap}</a></div>
                                        </div>`

                                    }else{

                                        partDownStore = partDownStore + `<div class="store" style="display: inline-flex;">
                                        <div class="storeName">${stores[i].name}</div>
                                        <div class="linksStores"><a href="http://maps.google.com/?q=${stores[i].point_latitude},${stores[i].point_longitude}" class="btnDefault" target="_blank">${seeMap}</a></div>
                                        </div>`
                                    }

                                }

                            }

                        }else{

                            for(let i=0; i<6; i++){

                                if(stores[i] != undefined){
                                    let web=stores[i].web;

                                    if(web !== '' && web !== null ){

                                        if(web.toLowerCase().indexOf('http') === -1 ){

                                            web="https://" + web;
                                        }
                                    }

                                    if(i===0){

                                        if(stores[i].name.toUpperCase().indexOf('FAMALIVING') > -1 && web !== '' && web !== null){

                                            partDownStore=`<div class="store" style="display: block;">
                                            <div class="storeName"><a href="${web}" class="btnDefault linkStore" target="_blank">${stores[i].name}</a></div>
                                            <div class="linksStores"><a href="http://maps.google.com/?q=${stores[i].point_latitude},${stores[i].point_longitude}" class="btnDefault" target="_blank">${seeMap}</a></div>
                                            </div>`
                                        }else{

                                            partDownStore=`<div class="store" style="display: block;">
                                            <div class="storeName">${stores[i].name}</div>
                                            <div class="linksStores"><a href="http://maps.google.com/?q=${stores[i].point_latitude},${stores[i].point_longitude}" class="btnDefault" target="_blank">${seeMap}</a></div>
                                            </div>`
                                        }

                                    }else{

                                        if(stores[i].name.toUpperCase().indexOf('FAMALIVING') > -1 && web !== '' && web !== null){

                                            partDownStore = partDownStore + `<div class="store" style="display: block;">
                                            <div class="storeName"><a href="${web}" class="btnDefault linkStore" target="_blank">${stores[i].name}</a></div>
                                            <div class="linksStores"><a href="http://maps.google.com/?q=${stores[i].point_latitude},${stores[i].point_longitude}" class="btnDefault" target="_blank">${seeMap}</a></div>
                                            </div>`

                                        }else{

                                            partDownStore = partDownStore + `<div class="store" style="display: block;">
                                            <div class="storeName">${stores[i].name}</div>
                                            <div class="linksStores"><a href="http://maps.google.com/?q=${stores[i].point_latitude},${stores[i].point_longitude}" class="btnDefault" target="_blank">${seeMap}</a></div>
                                            </div>`
                                        }

                                    }

                                }
                            }
                        }
                    }

                    if(marker.data.fotos) {
                        if (marker.data.fotos.length > 0) {

                            if(marker.data.fotos.length === 1){
                                sliderShop = `
                                <div class="wrap-slider">
                                    <div class="slider-shop">

                                    </div>
                                </div>`
                            }else{
                                sliderShop = `
                                <div class="wrap-slider">
                                    <div class="slider-shop">

                                    </div>
                                    <div class="wrap-progress">
                                        <div class="progress" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                            <span class="slider__label sr-only"></span>
                                        </div>
                                    </div>
                                </div>`
                            }



                            marker.data.fotos.forEach(function (imagen) {

                                slideritem = `
                                <div class="item-img js-openFull">
                                    <div class="img">
                                        <img src="${imagen}" alt="">
                                    </div>
                                </div>`
                                sliderList=sliderList+slideritem;
                            })
                        }
                    }

                    $('.container-shops .map-container-info').html(`
                    <div class="wrap-content-shop">
                        <div class="part-up d-flex">

                        </div>
                        <div class="part-down d-flex">
                            <div class="storyNear">

                            </div>
                        </div>
                    </div>
                    `);


                    $('.container-shops .map-container-info .wrap-content-shop .part-up').append(partUpDir);
                    $('.container-shops .map-container-info .wrap-content-shop .part-up').append(partUpContact);
                    $('.container-shops .map-container-info .wrap-content-shop .part-up .contact').append(partDownRedes);
                    $('.container-shops .map-container-info .wrap-content-shop .part-up .contact').append(btnContact);

                    $('.container-shops .map-container-info .part-down ').append(partDownStores);
                    $('.container-shops .map-container-info .part-down .storyNear').append(partDownStore);

                    if(marker.data.fotos) {
                        if (marker.data.fotos.length > 0) {
                            $('.container-shops .map-container-info').append(sliderShop);
                        }
                        $('.container-shops .map-container-info .slider-shop').append(sliderList);

                        this.sliders.initSliderShop();
                    }

                }

            });

            /**
             if(store.id===3166) {
                //console.log('124 init');
                //console.log('2687 init famaliving');
                new google.maps.event.trigger(marker, 'click');
            }
             */

        });


        var myStyleCluster = {styles: [{
                textColor: '#fff',
                width:40,
                height:40,
                url:'/images/fl-cluster.svg',
                anchorText:[0,0],
            }],

        };

        this._markerCluster = new MarkerClusterer(map, markers, myStyleCluster);
        //console.log('CLUSTERER', myStyleCluster);
        //console.log('Google Maps API version: ' + google.maps.version);

        $("#map").each((i, item) => {
            let $item = $(item);
            var provinciaContainer = $('#provincia_container');
            var self = this;
            var inputsearch = $('input[name="search"]');

            // Comienzo: Geolocalización del usuario, se centra en la posición actual
            function centerMap(position) {
                if (position.coords) {
                    let coordenadas=new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    var zoom = 8;
                    this._map.setCenter(coordenadas, zoom);
                }
            }


            //change a lat long 124

            let coordenadas=new google.maps.LatLng(38.6175787, -1.1288207);
            var zoom = 8;
            this._map.setCenter(coordenadas, zoom);
            if (this.DONT_CENTER_ON_INIT == 1) {
                var zoom = 8; // Ojo con poner 8, no nos sale bien Darlings
            }
            else {
                var zoom = 1;
            }
            this._map.setCenter(coordenadas, zoom);

            centerMap(coordenadas);

            // Fin: Geolocalización del usuario, se centra en la posición actual

            this.DONT_CENTER_ON_INIT = 0;


//************************************************** */ /* CONTROLES DE LOS SELECTORES DE PAIS Y PROVINCIAS ***************************************************/
            $('<style>.select2-container {\n' +
                '    width: 100% !important;\n' +
                '}</style>').appendTo('body');


            provinciaContainer.hide() //.css('visibility', 'hidden');


            $('.form-search-map select[name="pais"]').select2({});

            $('.form-search-map select[id="distance"]').select2({});
            $('.form-search-map .buttons #distance +.select2-container').hide();
            $('.form-search-map .buttons').find('.paisLabel').hide();

            $("#fcity").keyup(function(){
                if($("#fcity").val() !='' ){
                    $('.form-search-map .buttons #distance +.select2-container').show();
                    $('.form-search-map .buttons').find('.paisLabel').show();
                }else{
                    $('.form-search-map .buttons #distance +.select2-container').hide();
                    $('.form-search-map .buttons').find('.paisLabel').hide();
                }
            })

            $(".form-search-map select[name='pais']").on('change', (e) => {
                $item = $(e.currentTarget)
                var provinciaselect = $("#provincia_container");

                if ($item.find(":selected").attr("data-load-provinces") == "1") {
                    //changes
                    if (provinciaselect.find("input[type=text]").hasClass('loading-results')) {
                        provinciaselect.find("select[name='id_provincias']").select2('destroy');
                        provinciaselect.find("select").remove();
                        provinciaselect.find("input[type=text]").show();

                        $('.container-shops #fcity').val('');
                        $('.container-shops .buttons #distance +.select2-container').hide();
                        $('.container-shops .buttons').find('.paisLabel').hide();

                    } else {
                        //load firest
                        provinciaselect.find("input[type=text]").addClass('loading-results');

                    }

                    let val = parseInt($item.val());
                    let countriesZoom = [
                        90,// Alemania
                        68,//España
                        75,//Reino Unido,
                        82,//Francia
                    ];

                    var options = {
                        pais_zona: $item.find(":selected").val(),
                        lang: $("html").attr("lang")
                    }

                    $.ajax({
                        url: "/ajax.php?module=tiendas&action=provincias&pais_id=" + options.pais_zona,
                        data: null,
                        type: "get",
                        async: true,
                        dataType: "json",
                        beforeSend: function () {

                        },
                        success: (r) => {
                            //Display province field
                            provinciaContainer.show() //.css('visibility', 'visible');

                            provinciaselect.find("select[name='id_provincias']").select2('destroy');
                            provinciaselect.find("select").remove();
                            provinciaselect.find(".select2").remove();
                            provinciaselect.find("input[type=text]").hide();
                            provinciaselect.append("<select name=\"id_provincias\" class=\"form-control\"></select>");

                            provinciaselect.find("select").select2({data: r});
                            var option = new Option($("#todas_provincias").val(), 0);
                            option.selected = true;
                            provinciaselect.find("select").prepend(option);
                            provinciaselect.find("select").trigger("change");
                            var v = provinciaselect.find("select option").eq(0).html();
                            provinciaselect.find("input[type=text]").val(v);

                            provinciaselect.find("select").change((e) => {
                                $item = $(e.currentTarget)
                                var v = $item.find(":selected").html();
                                provinciaselect.find("input[type=text]").val(v);

                                $(".container-shops #fcity").val('');
                                $('.container-shops .buttons #distance +.select2-container').hide();
                                $('.container-shops .buttons').find('.paisLabel').hide();
                            });
                        }
                    });
                }
                else {
                    provinciaselect.find("select[name='id_provincias']").select2('destroy');
                    provinciaselect.find("select").remove();
                    provinciaselect.find("input[type=text]").val('');
                    provinciaselect.find("input[type=text]").removeClass('loading-results');
                    provinciaselect.find("input[type=text]").show();

                    //Hide Province
                    provinciaContainer.hide() //.css('visibility', 'hidden');
                }
            });


            $(".form-search-map #provincia").on("change", function () {

                setTimeout(function () {
                    $(".container-shops #fcity").val('');
                }, 10);
                $('.container-shops .buttons #distance +.select2-container').hide();
                $('.container-shops .buttons').find('.paisLabel').hide();

            });

            $(".title .show-filter").on("click", (e) => {
                $item = $(e.currentTarget)

                if ($item.hasClass("open")) {
                    $item.removeClass("open");
                    $('form').fadeOut({queue: false, duration: 'fast'});
                } else {
                    $item.addClass("open");
                    $('form').fadeIn({queue: false, duration: 'slow'});
                    $('.form-search-map select[name="pais"]').select2({});
                }
            });

            //Edicion de archivo
            $('.form-search-map #buscar').on('click', (e) => {
                $(".title .show-filter:visible").trigger("click");
                e.preventDefault();
                var $pais = $('.form-search-map select[name="pais"] option:selected');
                var $provincia = $('.form-search-map select[name="id_provincias"] option:selected');
                var $ciudad = $('.form-search-map input[name="fcity"]');
                var $query = [];
                var $zoom = $('.form-search-map #distance option:selected').val();
                var $search_city = false;
                this.NUMBER_OF_RESULTS = 0;

                if ($pais.text() != '' && $.isNumeric($pais.val())) {
                    $query.push($pais.text());
                    // Países
                    for (let i = 0; i < paisesjson_string[maplang].length; i++) {
                        if (this.utils.stripos(paisesjson_string[maplang][i].text, $pais.text()) !== false) {
                            this.NUMBER_OF_RESULTS = this.NUMBER_OF_RESULTS + 1;
                        }
                    }
                }

                if ($provincia.text() != '' && $.isNumeric($provincia.val()) && $provincia.val() != 0) {
                    $query.push($provincia.text());
                    if ($zoom > 6)

                        // Provincias
                        for (let i = 0; i < provinciasjson_string[maplang].length; i++) {
                            if (this.utils.stripos(provinciasjson_string[maplang][i].text, $provincia.text()) !== false) {
                                this.NUMBER_OF_RESULTS = this.NUMBER_OF_RESULTS + 1;
                            }
                        }
                }

                if ($ciudad.val() != '') {
                    $query.push($ciudad.val() + ' city');
                    this.NUMBER_OF_RESULTS = this.NUMBER_OF_RESULTS + 1;
                    $search_city = true;
                }

                if ($query.join(',') != '') {
                    this.callGeocode($query.join(','), $zoom);
                    $('.map-container-info').html('');
                }
            });

        });
    }

    callGeocode($query, $zoom){
        var count = ($query.match(/,/g) || []).length;
        let isValid=true;
        if(!$zoom){
            $zoom = 10;
        }
        var $pais = $('select[name="pais"] option:selected');
        var $provincia = $('select[name="id_provincias"] option:selected');
        var $ciudad = $('input[name="fcity"]');

        //console.log('$zoom: ', $zoom);
        var provinciaSearch = false;
        var countrySearch=false;
        var citySearch=false;

        if(count < 3 && count>=0) {

            if($pais.val() != '' && parseInt($pais.val()) != 0){
                countrySearch = true;
            }
            if($provincia.val() != '' && parseInt($provincia.val()) != 0){
                provinciaSearch = true;

            }
            if($ciudad.val() != ''){
                citySearch=true;
            }

        }



        //Capitalize the first word if is region search
        if($query.search("region") !== -1) {
            $query = $query.replace("city", "");
        }

        var $pais = $('select[name="pais"] option:selected');
        var $provincia = $('select[name="id_provincias"] option:selected');
        var $ciudad = $('input[name="fcity"]');


        if(($pais.val() == '' || $pais.val() == '0') && $ciudad.val() !== ''){
            isValid=false;
            $('#pais').addClass('err');
        }else{
            $('#pais').removeClass('err');
        }

        //console.log('isValid', isValid);
        if(isValid){
            // console.log('$query_callGeocode_2021:', $query);

            var icons = {
                famaliving: {
                    icon: '/images/redMarker.svg',
                },
                fama: {
                    icon: '/images/blueMarker.svg',
                },
                clicked: {
                    icon: '/images/blueMarkerChecked.svg',
                },
                clicked_famaliving: {
                    icon: '/images/redMarkerChecked.svg',
                }
            }


            $.ajax({
                url: "https://maps.googleapis.com/maps/api/geocode/json?address=" + $query + "&key=AIzaSyCZNQEfPfN99yGrp1s_M4CmYPiCO4vcdac",
                dataType: 'json'
            }).done((data) => {

                let position = {
                    coords: {
                        longitude: null,
                        latitude: null,
                    }
                };
                let ne, sw;

                if(!!data.results && Array.isArray(data.results)
                    && data.results.length
                    && !!data.results[0].geometry
                    && !!data.results[0].geometry.location
                ){
                    position.coords.longitude = data.results[0].geometry.location.lng;
                    position.coords.latitude = data.results[0].geometry.location.lat;

                    if(typeof data.results[0].geometry.bounds !== 'undefined'
                        && typeof data.results[0].geometry.bounds.northeast !== 'undefined'
                        && typeof data.results[0].geometry.bounds.southwest !== 'undefined'){

                        ne = parseFloat(data.results[0].geometry.location.lat);
                        sw = parseFloat(data.results[0].geometry.location.lng);

                        this.searched = true;

                        let coordenadas=new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

                        this._map.setCenter({lat:position.coords.latitude, lng:position.coords.longitude}, $zoom);

                        //console.log('mierda', provinciaSearch);
                        var meters = 0;
                        if(provinciaSearch === false && countrySearch===false) {
                            if ($zoom <= 10) {
                                meters = 80000;
                            } else if ($zoom >= 12) {
                                meters = 10000;
                            }
                        }

                        if(provinciaSearch === false && countrySearch === false){
                            //Searc all (country, province, city)


                            //Earth’s radius, sphere
                            let earthRadious = 6378137

                            //offsets in meters
                            let dn = meters;
                            let de = meters;

                            //Coordinate offsets in radians
                            let dLat = dn/earthRadious;
                            let dLon = de/(earthRadious * Math.cos(Math.PI * ne/180));

                            // Mapbox contiene 4 posiciones, las esquinas de un cuadrado que tiene de alto y ancho la distancia
                            // marcada en el selector de front para la busqueda
                            // nlat es el punto superior, elon el derecho, slat el punto superior y wlon el izquierdo

                            var mapBox = {};
                            mapBox.nlat = parseFloat(ne + (dLat * 180 / Math.PI));
                            mapBox.elon = parseFloat(sw + (dLon * 180 / Math.PI));
                            mapBox.slat = parseFloat(ne - (dLat * 180 / Math.PI));
                            mapBox.wlon = parseFloat(sw - (dLon * 180 / Math.PI));

                            var everyShopsFeature = this._markers;

                            var $visibleShops = [];
                            var $notVisiblesShops = [];
                            var markersToShow = [];
                            this._markerCluster.clearMarkers();

                            everyShopsFeature.forEach(function (feature) {
                                let shop = feature.data;

                                if (
                                    (shop.point_latitude < mapBox.nlat && shop.point_longitude < mapBox.elon) &&
                                    (shop.point_latitude > mapBox.slat && shop.point_longitude > mapBox.wlon) &&
                                    ($('#pais').val() == shop.country_id)
                                ) {
                                    if(feature.data.name.toUpperCase().indexOf('FAMALIVING') > -1){
                                        feature.setIcon(icons.famaliving.icon);
                                    }else {
                                        feature.setIcon(icons.fama.icon);
                                    }
                                    $visibleShops.push(feature.data);
                                    feature.setVisible(true);
                                    markersToShow.push(feature);

                                } else{

                                    if(feature.data.name.toUpperCase().indexOf('FAMALIVING') > -1){
                                        feature.setIcon(icons.famaliving.icon);
                                    }else {
                                        feature.setIcon(icons.fama.icon);
                                    }

                                    $notVisiblesShops.push(feature);
                                    feature.setVisible(false);
                                }
                            });


                            this._markerCluster.addMarkers(markersToShow);


                        }else{
                            if(provinciaSearch === false && countrySearch===true && citySearch===false){

                                var everyShopsFeature = this._markers;
                                var $visibleShops = [];
                                var $notVisiblesShops = [];
                                var markersToShow = [];
                                this._markerCluster.clearMarkers();

                                everyShopsFeature.forEach(function (feature) {
                                    let shop = feature.data;

                                    if (($('#pais').val() == shop.country_id)) {
                                        if(feature.data.name.toUpperCase().indexOf('FAMALIVING') > -1){
                                            feature.setIcon(icons.famaliving.icon);
                                        }else {
                                            feature.setIcon(icons.fama.icon);
                                        }

                                        $visibleShops.push(feature.data);
                                        feature.setVisible(true);
                                        markersToShow.push(feature);

                                    } else{

                                        if(feature.data.name.toUpperCase().indexOf('FAMALIVING') > -1){
                                            feature.setIcon(icons.famaliving.icon);
                                        }else {
                                            feature.setIcon(icons.fama.icon);
                                        }

                                        $notVisiblesShops.push(feature);
                                        feature.setVisible(false);
                                    }
                                });
                                this._markerCluster.addMarkers(markersToShow);

                                $zoom = 3;

                            }else if(provinciaSearch === true && countrySearch===true && citySearch===false){
                                //console.log('Todas las tiendas de provinciaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
                                var everyShopsFeature = this._markers;
                                var $visibleShops = [];
                                var $notVisiblesShops = [];
                                var markersToShow = [];
                                this._markerCluster.clearMarkers();
                                //console.log('id_pais:', $('#pais').val());
                                //console.log('id_provincias:', $('#id_provincias').val());
                                let provincia=$('#id_provincias').val().toUpperCase();
                                var $provincia = $('select[name="id_provincias"] option:selected');
                                //console.log('provincia: ', provincia, $provincia.val());

                                everyShopsFeature.forEach(function (feature) {
                                    let shop = feature.data;

                                    if (($('#pais').val() == shop.country_id) && ($provincia.val() == shop.region_id)) {

                                        if(feature.data.name.toUpperCase().indexOf('FAMALIVING') > -1){

                                            feature.setIcon(icons.famaliving.icon);
                                        }else {
                                            feature.setIcon(icons.fama.icon);
                                        }

                                        $visibleShops.push(feature.data);
                                        feature.setVisible(true);
                                        markersToShow.push(feature);

                                    } else{
                                        if(feature.data.name.toUpperCase().indexOf('FAMALIVING') > -1){
                                            feature.setIcon(icons.famaliving.icon);
                                        }else {
                                            feature.setIcon(icons.fama.icon);
                                        }

                                        $notVisiblesShops.push(feature);
                                        feature.setVisible(false);
                                    }
                                });
                                this._markerCluster.addMarkers(markersToShow);

                                $zoom = 6;

                            }else if(countrySearch===true && citySearch===true){

                                //Searc all (country, province, city)
                                if ($zoom <= 10) {
                                    meters = 80000;
                                } else if ($zoom >= 12) {
                                    meters = 10000;
                                }
                                //console.log('por ciudad', meters);
                                //Earth’s radius, sphere
                                let earthRadious = 6378137

                                //offsets in meters
                                let dn = meters;
                                let de = meters;

                                //Coordinate offsets in radians
                                let dLat = dn/earthRadious;
                                let dLon = de/(earthRadious * Math.cos(Math.PI * ne/180));

                                // Mapbox contiene 4 posiciones, las esquinas de un cuadrado que tiene de alto y ancho la distancia
                                // marcada en el selector de front para la busqueda
                                // nlat es el punto superior, elon el derecho, slat el punto superior y wlon el izquierdo

                                var mapBox = {};
                                mapBox.nlat = parseFloat(ne + (dLat * 180 / Math.PI));
                                mapBox.elon = parseFloat(sw + (dLon * 180 / Math.PI));
                                mapBox.slat = parseFloat(ne - (dLat * 180 / Math.PI));
                                mapBox.wlon = parseFloat(sw - (dLon * 180 / Math.PI));

                                var everyShopsFeature = this._markers;

                                var $visibleShops = [];
                                var $notVisiblesShops = [];
                                var markersToShow = [];
                                this._markerCluster.clearMarkers();

                                everyShopsFeature.forEach(function (feature) {
                                    let shop = feature.data;

                                    if (
                                        (shop.point_latitude < mapBox.nlat && shop.point_longitude < mapBox.elon) &&
                                        (shop.point_latitude > mapBox.slat && shop.point_longitude > mapBox.wlon) &&
                                        ($('#pais').val() == shop.country_id)
                                    ) {
                                        if(feature.data.name.toUpperCase().indexOf('FAMALIVING') > -1){
                                            feature.setIcon(icons.famaliving.icon);
                                        }else {
                                            feature.setIcon(icons.fama.icon);
                                        }
                                        $visibleShops.push(feature.data);
                                        feature.setVisible(true);
                                        markersToShow.push(feature);

                                    } else{

                                        if(feature.data.name.toUpperCase().indexOf('FAMALIVING') > -1){
                                            feature.setIcon(icons.famaliving.icon);
                                        }else {
                                            feature.setIcon(icons.fama.icon);
                                        }

                                        $notVisiblesShops.push(feature);
                                        feature.setVisible(false);
                                    }
                                });


                                this._markerCluster.addMarkers(markersToShow);
                            }

                        }

                        //console.log('visibleShops', $visibleShops);
                        this._storeNear=$visibleShops;

                        //console.log('zoom', $zoom);
                        if($zoom == 12 ){
                            $visibleShops.forEach(function (shop){
                                var coordenadas=new google.maps.LatLng(shop.point_longitude,shop.point_latitude);
                            });

                            this._map.setCenter(coordenadas);
                            this._map.setZoom(11);

                        }else if($zoom == 10){
                            $visibleShops.forEach(function (shop){
                                var coordenadas=new google.maps.LatLng(shop.point_longitude,shop.point_latitude);
                            });
                            this._map.setCenter(coordenadas);
                            this._map.setZoom(8);
                        }else if($zoom >= 6 && $zoom < 10){
                            $visibleShops.forEach(function (shop){
                                var coordenadas=new google.maps.LatLng(shop.point_longitude,shop.point_latitude);
                            });
                            this._map.setCenter(coordenadas);
                            this._map.setZoom(7);
                        }else if($zoom < 6){
                            $visibleShops.forEach(function (shop){
                                var coordenadas=new google.maps.LatLng(shop.point_longitude,shop.point_latitude);
                            });
                            this._map.setCenter(coordenadas);
                            this._map.setZoom(5);
                        }

                    }
                    else {
                        if($query.search("region") === -1){
                            this.callGeocode($query + " region", $zoom);
                        }
                        return false;
                    }
                }
            });
        }
    }

    /**
    @deprecated
     */
    autocomplete(text, return_type) {
        //console.log('autocomplete_2021');
        if (text.length < 3) return;
        var div = $("#map div.autocomplete .autocompletecontent");
        this.NUMBER_OF_RESULTS = 0;

        // Búsqueda
        if (return_type == this.GET_JS_AUTOCOMPLETE) {
            $("#map div.shop").each((i, item) => {

                $(item).attr("data-show", "0");
            });
        }

        // Tiendas
        for (i = 0; i < tiendasjson_string[maplang].length; i++) {
            if (this.utils.stripos(tiendasjson_string[maplang][i].text, text) !== false) {
                div.append('<a href="#" data-attr="' + tiendasjson_string[maplang][i].attr + '" data-attr-val="' + tiendasjson_string[maplang][i].attr_val + '">' + tiendasjson_string[maplang][i].text + '</a>');
                // Búsqueda
                if (return_type == this.GET_JS_AUTOCOMPLETE) {
                    //$("#map div.shop[data-" + tiendasjson_string[maplang][i].attr + "='" + tiendasjson_string[maplang][i].attr_val + "']").attr("data-show", "1").removeClass("hidden");
                    this.NUMBER_OF_RESULTS = this.NUMBER_OF_RESULTS + 1;
                }
            }
        }

        // Provincias
        for (i = 0; i < provinciasjson_string[maplang].length; i++) {
            if (this.utils.stripos(provinciasjson_string[maplang][i].text, text) !== false) {
                div.append('<a href="#" data-attr="' + provinciasjson_string[maplang][i].attr + '" data-attr-val="' + provinciasjson_string[maplang][i].attr_val + '">' + provinciasjson_string[maplang][i].text + '</a>');
                // Búsqueda
                if (return_type == this.GET_JS_AUTOCOMPLETE) {
                    //$("#map div.shop[data-" + provinciasjson_string[maplang][i].attr + "='" + provinciasjson_string[maplang][i].attr_val + "']").attr("data-show", "1").removeClass("hidden");
                    this.NUMBER_OF_RESULTS = this.NUMBER_OF_RESULTS + 1;
                }
            }
        }

        // Países
        for (i = 0; i < paisesjson_string[maplang].length; i++) {
            if (this.utils.stripos(paisesjson_string[maplang][i].text, text) !== false) {
                div.append('<a href="#" data-attr="' + paisesjson_string[maplang][i].attr + '" data-attr-val="' + paisesjson_string[maplang][i].attr_val + '">' + paisesjson_string[maplang][i].text + '</a>');
                // Búsqueda
                if (return_type == this.GET_JS_AUTOCOMPLETE) {
                    //$("#map div.shop[data-" + paisesjson_string[maplang][i].attr + "='" + paisesjson_string[maplang][i].attr_val + "']").attr("data-show", "1").removeClass("hidden");
                    this.NUMBER_OF_RESULTS = this.NUMBER_OF_RESULTS + 1;
                }
            }
        }
        div.parent().addClass("active");

    }

    /**
     *
     * @param {*} text
     * @deprecated
     */
    search(text) {
        //console.log('search_2021');
        this.autocomplete(text, this.GET_JS_AUTOCOMPLETE);
        // Reestablecemos la búsqueda
        if ($.trim(text) == "" || this.NUMBER_OF_RESULTS == 0) {


        }
        else {
            $("#map div.autocomplete .autocompletecontent").html("");
            this.DONT_CENTER_ON_INIT = 1;
            this.init();
        }
        $("#map div.autocomplete").removeClass("active");
        $("#map-search a.search").hide();
        $("#map-search a.closesearch").addClass("active");
        this.init();
    }

}
