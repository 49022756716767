export class GalleryBox {
    $modal = null;
    isOpen = false;
    myGalleryBox = null;
    myGalleryBoxFin = null;

    constructor() {
        this.init()
    }

    init() {
        console.log('GalleryBox loaded');

        //Pasador con thumbs
        if ($(".pasadorPost:not(.isOne)").length > 0 || $(".mygallerybox .swiper-id").length > 0 || $(".mygallerybox_finished .swiper-id").length > 0) {

            if ($('.slider-detail-store').length < 0) {
                if ($(".pasadorPost:not(.isOne)").length > 0) {
                    var detail_new_swiper = new Swiper(".pasadorPost:not(.isOne)", {
                        centeredSlides: false,
                        slidesPerView: 'auto',
                        spaceBetween: 15,
                        slideToClickedSlide: true,
                        preloadImages: false,
                        lazyLoadingInPrevNext: true,
                        lazyLoading: true,
                        prevButton: ".control-prev",
                        nextButton: ".control-next",
                        autoplay: false, // Rotar automáticamente 3 segundos por defecto
                        loop: false, // Opción de modo de bucle
                        grabCursor: true,
                        breakpoints: {

                            991: {
                                centeredSlides: false,
                                spaceBetween: 8,
                            },

                        }
                    });
                }
            } else {
                if ($(".pasadorPost.slider-detail-store:not(.isOne)").length > 0) {
                    setTimeout(() => {
                        var detail_new_swiper = new Swiper(".pasadorPost.slider-detail-store:not(.isOne)", {
                            centeredSlides: false,
                            slidesPerView: 1.5,
                            spaceBetween: 15,
                            slideToClickedSlide: true,
                            preloadImages: false,
                            lazyLoadingInPrevNext: true,
                            lazyLoading: true,
                            prevButton: ".control-prev",
                            nextButton: ".control-next",
                            autoplay: false, // Rotar automáticamente 3 segundos por defecto
                            loop: false, // Opción de modo de bucle
                            grabCursor: true,
                            resizeObserver: true,
                            breakpoints: {

                                991: {
                                    slidesPerView: 2.2,
                                    centeredSlides: false,
                                    spaceBetween: 0,
                                },
                                768: {
                                    slidesPerView: 1.2,
                                    centeredSlides: false,
                                    spaceBetween: 0,
                                },

                            }
                        });
                        //detail_new_swiper.reset();
                    }, 1000)

                }
            }

            if ($(".detail-blog-famaliving2023 .pasadorPost.swiper-container").length > 0 ) {
                var detail_new_swiper = new Swiper(".detail-blog-famaliving2023 .pasadorPost.swiper-container", {
                    centeredSlides: false,
                    slidesPerView: 1.5,
                    spaceBetween: 15,
                    slideToClickedSlide: true,
                    preloadImages: false,
                    lazyLoadingInPrevNext: true,
                    lazyLoading: true,
                    prevButton: ".control-prev",
                    nextButton: ".control-next",
                    autoplay: false, // Rotar automáticamente 3 segundos por defecto
                    loop: false, // Opción de modo de bucle
                    grabCursor: true,
                    resizeObserver: true,
                    breakpoints: {

                        991: {
                            slidesPerView: 2.2,
                            centeredSlides: false,
                            spaceBetween: 0,
                        },
                        768: {
                            slidesPerView: 1.2,
                            centeredSlides: false,
                            spaceBetween: 0,
                        },

                    }
                });
            }

            //Galeria en popup
            if ($(".mygallerybox .swiper-id").length > 0) {
                var mygallerybox = new Swiper(".mygallerybox .swiper-id", {
                    centeredSlides: false,
                    slidesPerView: 1,
                    slideToClickedSlide: true,
                    preloadImages: false,
                    lazyLoading: true,
                    lazyLoadingInPrevNext: true,
                    keyboardControl: true,
                    mousewheelControl: true,
                    prevButton: ".swiper-button-prev:not(.fin)",
                    nextButton: ".swiper-button-next:not(.fin)",
                    spaceBetween: 0,

                });
            }

            //Galeria en popup_fin
            if ($(".mygallerybox_finished .swiper-id").length > 0) {
                var mygallerybox_fin = new Swiper(".mygallerybox_finished .swiper-id", {
                    centeredSlides: false,
                    slidesPerView: 1,
                    slideToClickedSlide: true,
                    preloadImages: false,
                    lazyLoading: true,
                    lazyLoadingInPrevNext: true,
                    keyboardControl: true,
                    mousewheelControl: true,
                    prevButton: ".swiper-button-prev.fin",
                    nextButton: ".swiper-button-next.fin",
                    spaceBetween: 0,
                    grabCursor: true,
                });
            }

            this.myGalleryBox = mygallerybox;
            this.myGalleryBoxFin = mygallerybox_fin;
            this.eventListeners();
        }

        setTimeout((e) => {
            $(window).trigger("resize");
        }, 1000)
    }

    eventListeners() {

        $(".pasadorPost").find(".swiper-slide").on("click",(e) => {
            let $elem = $(e.currentTarget);
            if (this.myGalleryBox.length == 1) {
                this.open($($elem).index());
            } else {
                if ($('.new-blog .pasadorPost').length > 0) {
                    this.open($($elem).index(), $($elem).closest(".new-blog .pasadorPost").data("id"));
                } else if ($('.new-store').length > 0) {
                    this.open($($elem).index(), $($elem).closest(".slider").data("id"));
                }
            }
        });

        $(".mygallerybox_finished .btn-close").on("click", (e) => {
            let $elem = $(e.currentTarget);
            this.close_fin();
        });

        $(".mygallerybox .btn-close").on("click", (e) => {
            if ($(".swiper-slide-active").find("video").length > 0) {
                $(".swiper-slide-active").find("video")[0].pause();
            }
            this.close();
        });

        $("#thumb-fotos-xs").find(".swiper-slide").on("click", (e) => {
            e.preventDefault();
            this.open($(e.currentTarget).index(), "fotos-mobile");
        });

        $("#thumb-clientes-xs").find(".swiper-slide").on("click", (e) => {
            e.preventDefault();
            this.open($(e.currentTarget).index(), "clientes-mobile");
        });

        $("#thumb-videos").find(".thumb").on("click", (e) => {
            e.preventDefault();
            if ($(window).width() < 992) {
                this.open($(e.currentTarget).index(), "videos-mobile");
            }
        });
    }

    open(index, id) {
        var mygallerybox = this.myGalleryBox;

        if (index == null)
            index = 0;

        if (id == null) {
            id = "";
        }

        else if (!$.isNumeric(id)) {
            let idOrigin=id;
            id = "#" + id;
            if (Array.isArray(mygallerybox)) {
                if (id == "#fotos-mobile") {
                    mygallerybox = mygallerybox[0];
                }
                else if (id == "#clientes-mobile") {
                    mygallerybox = mygallerybox[1];
                }
                else if ($("#clientesid-mobile").length > 0 && id == "#videos-mobile") {
                    mygallerybox = mygallerybox[2];
                }
                else if ($("#clientesid-mobile").length == 0 && id == "#videos-mobile") {
                    mygallerybox = mygallerybox[1];
                }

            }

            if($('.new-store').length>0){
                mygallerybox = new Swiper($('.new-store .mygallerybox[data-slider="'+ id +'"] .swiper-id'), {
                    centeredSlides: true,
                    slidesPerView: 1,
                    slideToClickedSlide: true,
                    preloadImages: false,
                    lazyLoading: true,
                    lazyLoadingInPrevNext: true,
                    keyboardControl: true,
                    mousewheelControl: true,
                    prevButton: $('.new-store .mygallerybox[data-slider="'+ id +'"] .swiper-id').find(".swiper-button-prev:not(.fin)"),
                    nextButton: $('.new-store .mygallerybox[data-slider="'+ id +'"] .swiper-id').find(".swiper-button-next:not(.fin)"),
                    spaceBetween: 0
                });

            }
        }
        else {
            if (Array.isArray(mygallerybox)) {
                var idx = null;
                $(".pasador-text").each(function (i, elem) {
                    if ($(this).data("id") == id) {
                        idx = i;
                    }
                });
                $(".pasador-video").each(function (i, elem) {
                    if ($(this).data("id") == id) {
                        idx = id;
                    }
                });

                mygallerybox = mygallerybox[idx];
            }
            id = "#mygall" + id;
        }

        if($('.new-store').length<=0 && $('.new-blog').length<=0){

            mygallerybox.slideTo(index, 1);
        }else if($('.new-blog').length>0){
            var mygallerybox = new Swiper($('.new-blog .mygallerybox .swiper-id'), {
                centeredSlides: false,
                slidesPerView: 1,
                slideToClickedSlide: true,
                preloadImages: false,
                lazyLoading: true,
                lazyLoadingInPrevNext: true,
                keyboardControl: true,
                mousewheelControl: true,
                prevButton: ".swiper-button-prev:not(.fin)",
                nextButton: ".swiper-button-next:not(.fin)",
                spaceBetween: 0
            });

            mygallerybox.slideTo(index, 1);

        }else if($('.new-store').length>0){
            mygallerybox.slideTo(index,400);
        }

        mygallerybox.on('onTransitionEnd', () => {
            var video = $(id).find('.swiper-slide-active video');
            if (video.length && !video.attr('src')) {
                video.attr('src', video.data('src'));
            }
        });

        if($('.new-store').length>0){
            $('.new-store .mygallerybox[data-slider="'+ id +'"]').addClass("preopen");
            $('.new-store .mygallerybox[data-slider="'+ id +'"]').addClass("open").removeClass("preopen");
        }else if($('.new-blog').length>0){
            $(".new-blog .mygallerybox").addClass("preopen");
            $(".new-blog .mygallerybox").addClass("open").removeClass("preopen");
        }else{
            $(id + ".mygallerybox").addClass("preopen");
            $(id + ".mygallerybox").addClass("open").removeClass("preopen");
        }

        $("body, html").addClass("stop-scroll");
    }

    open_fin(index, id) {

        var mygallerybox_fin = this.myGalleryBoxFin;
        if (index == null){
            index = 0;
        }

        if (id == null) {
            id = "";
        }
        else {
            if (Array.isArray(mygallerybox_fin)) {
                //console.log('open_fin2');
                var idx = null;
                $(".pasador-text").each((i, elem) => {
                    if ($(elem).data("id") == id) {
                        idx = i;
                    }
                });
                mygallerybox_fin = mygallerybox_fin[idx];
            }
            id = "#mygall" + id;
        }

        mygallerybox_fin.slideTo(index, 1);

        $(".mygallerybox_finished").addClass("preopen");
        $(".mygallerybox_finished").addClass("open").removeClass("preopen");
        $("body, html").addClass("stop-scroll");
    }
    close_fin() {
        $(".mygallerybox_finished").removeClass("open");
        $("body, html").removeClass("stop-scroll");
    }

    close(id) {
        if (id == null)
            id = "";

        $(id + ".mygallerybox").removeClass("open");
        $("body, html").removeClass("stop-scroll");
    }

    loadVid(id) {
        if (id == null)
            id = "";

        $(".video-slide").each((key, item) => {
            if ($(item).data('id') == id) {
                var src = $(item).find('video').data('src');
                $(item).find('video').attr('src', src);
            }
        });
    }

}
