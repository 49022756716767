//use newsletter (famaliving)

import {FamaUtils} from "../../classes/FamaUtils";

export class Newsletter {
    utils = fama.utils || FamaUtils.getInstance();

    constructor() {
        this.init();
    }

    init() {
        console.log('init newsletter');
        this.eventListeners();
        this.seeModalScroll();

        $('[id^="mailfastsuscription"]').each( (key, item) => {
            $('input[name="mailfastsuscription"]').removeClass('error');

            $(".btn-mailfastsuscription").off().on('click',(e) => {
                console.log('newsletter famaliving2023');

                e.preventDefault();

                var isValid=true;
                if($(item).closest('.form').find('input[name="mailfastsuscription"]').val() === ''){
                    $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box').addClass('error');
                    $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box').addClass('error');
                    var errEmpty=$(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box').find('.err').data('errempty');
                    $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box.error').find('.err').html(errEmpty);
                    $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box.error').find('.err').show();
                    isValid=false;
                }else{
                    if(!this.utils.isEmailValid($(item).closest('.form').find('input[name="mailfastsuscription"]').val())){
                        $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box').addClass('error');
                        var errPattern=$(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box.error').find('.err').data('errpattern');
                        $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box.error').find('.err').html(errPattern);
                        $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box.error').find('.err').show();
                        isValid=false;
                    }else{
                        $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box.error').find('.err').hide();
                        $(item).closest('.form').find('input[name="mailfastsuscription"]').closest('.input-box').removeClass('error');
                    }

                }

                if(!$(item).closest('.form').find('.acept').prop('checked')) {
                    isValid=false;
                    $(item).closest('.form').find('.acept').closest('.privacy').addClass('error');
                    $(item).closest('.form').find('.acept').closest('.privacy').find('.err').show();
                }else{
                    $(item).closest('.form').find('.acept').closest('.privacy').removeClass('error');
                    $(item).closest('.form').find('.acept').closest('.privacy').find('.err').hide();
                }

                if (isValid) {
                    var options = {
                        email: $(item).closest('.form').find('input[name="mailfastsuscription"]').val(),
                        lang: $('html').attr('lang')
                    }

                    let self=$(item);

                    $.ajax({
                        url: "/ajax.php?module=newsletter&action=fastsub",
                        data: options,
                        type: "post",
                        async: true,
                        dataType: "json",
                        beforeSend: function () {},
                        success: (r) => {
                            if (r.status == 'ok') {
                                self.closest('.form').find('.input-box').each((key, item) => {
                                    $(item).fadeOut('slow');
                                });

                                self.closest('.form').find('.title').html('');
                                self.closest('.form').find('.wrap-btnDefault').fadeOut('slow');
                                self.closest('.form').find('.privacy.policy').fadeOut('slow');
                                console.log($("#mensaje_suscribirse").val());
                                self.closest('.form').find('.box-content').html("<div class='well'>" + $("#mensaje_suscribirse").val() + "</div>").fadeIn('slow');


                                $('body,html').removeClass('stop-scroll');

                                setTimeout(() => {
                                    if(!this.utils.checkCookie('isNewsletterCheck')) {
                                        let days=9999;
                                        this.utils.setCookie("isNewsletterCheck", '1', days);
                                    }else{
                                        let days=9999;
                                        this.utils.setCookie("isNewsletterCheck", '1', days);
                                    }
                                    $('.form-newsletter .error').removeClass('error');
                                    $('.form-newsletter .err').hide();
                                    $('.form-newsletter.content-open-close').removeClass('isOpen');

                                }, 2500)


                            } else {
                                self.closest('.form').find('input[name="mailfastsuscription"]').addClass('error');
                            }
                        }
                    });
                }
            });

        });
    }

    openNewsletterForm() {
        $('.form-newsletter').addClass('isOpen');
        $('html, body').addClass('stop-scroll');
    }

    seeModalScroll() {
        $(window).on('scroll', () => {
            if(!this.utils.checkCookie('isNewsletterCheck') && $('.geo-content').css('display')=='none') {
                var body = $('body');
                var height = window.innerHeight;

                if ($(window).scrollTop() >= body.position().top + height) {
                    $('.js-openNewsletterForm').trigger('click');
                    $('html, body').removeClass('stop-scroll');
                }
            }
        });
    }

    eventListeners() {
        $('.js-openNewsletterForm').on('click', () => {this.openNewsletterForm()});
    }

}

