//use blog (famaliving) Blog Famaliving 2023
import $ from 'jquery'

'use strict'
import axios from 'axios'
import {FamaUtils} from "../../classes/FamaUtils";

export class Blog {
    utils = FamaUtils.getInstance();
    /** Selectors **/
    $entryContainer = $('#js-blog .js-blog-entry-container')
    $currentPage = $('#js-blog .js-page-value')
    $currentPageID = $('#js-blog .js-pageId-value')
    $currentLocale = $('#js-blog .js-locale-value')
    $commentsLoader = $('#js-blog .comment-loader')

    currentOffset = 0;
    fin= false;

    /** Api Endpoints **/
    static loadMorePosts = '/api/get-more-entries';
    static findShopPosts = '/api/search-blog-entries-by-store';

    constructor() {
        this.init();
    }

    init() {
        this.eventListeners();

        if (!this.utils.isLoad("select2", "$")) {
            return;
        }

        this.initSelect2();
    }

    eventListeners() {
        $(document).on('scroll', (e) => {this.onScroll()});
        $('#blog-shops').on('select2:select', (e) => {this.changeShop(e)});
    }

    initSelect2(){
        if( $("select[name='blog-shops']").length > 0){
            $("select[name='blog-shops']").select2();
        }
    }

    changeShop(e){
        let $elem = $(e.currentTarget);
        let list=$('#js-blog .blog-entry-container');
        list.html('');

        this.$currentPage[0].value=2;
        this.currentOffset = 0;

        let data = {
            store_id: $elem.val(),
            lang: document.documentElement.lang,
            locale: this.$currentLocale[0].value,
        }

        axios
            .post(Blog.findShopPosts, data)
            .then( (response) => {
                response.data.blogItems.forEach((element) => {
                    let wrap=$('#js-blog.new-blog');
                    wrap.addClass('filterShop');
                    let entry = this.setterEntryBlog(element);
                    list[0].innerHTML += entry;
                });

            })
        .catch(function (error) {
            this.utils.toast.error(error)
        })

    }

    onScroll() {
        let contenido;
        contenido=Math.round($('#js-blog .two-columns-wrap').outerHeight() + Math.round($('#js-blog .two-columns-wrap').offset().top));
        let elem=$('#blog-shops').val();
        if (window.scrollY + window.innerHeight >= contenido && !this.fin) {
            this.fin=true;

            let data = {
                pageId: this.$currentPageID[0].value,
                page: this.$currentPage[0].value,
                locale: this.$currentLocale[0].value,
            }
            if($('.filterShop').length > 0){
                data.store_id=elem;
            }

            if (this.currentOffset <= data.page) {
                this.$commentsLoader.show();
                axios
                    .post(Blog.loadMorePosts, data)
                    .then((response) => {
                        this.currentOffset = data.page;
                        let locale = this.$currentLocale[0];

                        response.data.blogItems.forEach(element => {

                        // TODO: Relacionar el local con la inserccion dinámica
                        let entry = this.setterEntryBlog(element);

                        this.$entryContainer[0].innerHTML += entry;
                        });

                        this.$currentPage[0].value++;

                        this.$commentsLoader.hide();
                        this.fin=false;
                    })
                .catch((error) => {
                    this.utils.toast.error(error)
                })
            }
        }

    }

    setterEntryBlog(element)
    {
        let entry = `<div class="blog-entry">
                        <div class="blog-content">
                            <div class="aux-item">
                                <a href="${element.detailUrl}" class="img-link" >
                                    <div class="aux-img">
                                        <div class="wrap-img">
                                            <img src="${element.imagen}" loading="lazy" class="lazyload img" alt="${element.nombre_imagen}" title="${element.nombre_imagen}">
                                        </div>
                                    </div>
                                </a>
                            </div>

                            <div class="block-text text generic">
                                <div class="badge-blog">
                                    <p class="likes">
                                    <span class="likes-count">${element.likes_count}</span>`
                                if (!document.cookie.includes(`liked_${element.id}_32`)) {
                                    entry += `<a href="#" class="like-button" data-item="${element.id}" data-type="32">
                                                <i class="fa fa-heart-o heart heart--pink" aria-hidden="true"></i>
                                              </a>`;
                                }
                                else {
                                    entry += `<a href="#" class="liked" data-item="${ element.id }" data-type="32">
                                                <i class="fa fa-heart heart heart--pink" aria-hidden="true"></i>
                                              </a>`
                                }

                            entry +=`</p>
                        </div>`
                        entry +=`<div class="info">
                                    <div class="date-info">${element.date}</div>
                                </div>`
                        var subs = element.descripcion.substring(0, 150);
                        entry +=`<span class="author a" href="${element.autorUrl}">${element.autor}</span>
                                <a href="${element.detailUrl}">
                                    <h2 class="block-title">${element.titulo}</h2>
                                </a>
                                <p class="desc d-none">${subs}...</p>
                                <hr>
                            </div>
                        </div>
                    </div>`
        ;
        return entry;
    }
}
